import React from 'react';
import * as styles from './styles.module.scss';
import classNames from 'classnames';

export default function Button(props) {
  const { variant = 'blue', children = '', className = '', ...restProps } = props;

  return (
    <button {...restProps} className={classNames(styles.button, styles[variant], className)}>{children}</button>
  )
}
